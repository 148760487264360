import React, { useState, useEffect } from "react";
import Swiper from "react-swipeable-views";
import Popup from "../Popup/index";
import * as styles from "./style.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

export default function Index({ list, index, closePopUp }) {
  const [indexPopup, setIndexPopup] = useState(null);

  useEffect(() => {
    setIndexPopup(index);
  }, []);

  const increaseIndex = () => {
    indexPopup === 5 ? setIndexPopup(0) : setIndexPopup(indexPopup + 1);
  };
  const decreaseIndex = () => {
    indexPopup === 0 ? setIndexPopup(5) : setIndexPopup(indexPopup - 1);
  };
  return (
    <div className={styles.contentPopup}>
      {indexPopup !== null && (
        <div className={styles.content}>
          <Swiper index={indexPopup} enableMouseEvents>
            {list.map((res, index) => {
              return (
                <Popup
                  closePopUp={closePopUp}
                  index={index}
                  img={res.node.featuredImage.node.localFile}
                  image={res?.node.popup?.image?.localFile}
                  content={res?.node.popup.popupContent}
                  key={res.name}
                  text={res.content}
                  to={`/blog/${res.node.slug}`}
                  category={res.node.title}
                />
              );
            })}
          </Swiper>
          <div className={styles.arrows}>
            <div
              className={styles.leftContainer}
              onClick={() => decreaseIndex()}
              onKeyDown={() => decreaseIndex()}
            >
              <div className={styles.arrow}>
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  style={{ height: 20, width: 12, color: "#D0D0D0" }}
                />
              </div>
            </div>
            <div
              className={styles.rightContainer}
              onClick={() => increaseIndex()}
              onKeyDown={() => increaseIndex()}
            >
              <div className={styles.arrow}>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  style={{ height: 20, width: 12, color: "#D0D0D0" }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
