// extracted by mini-css-extract-plugin
export var caseStudies = "style-module--caseStudies--KiwBt";
export var desc = "style-module--desc--ZGFUL";
export var email = "style-module--email--vfUVs";
export var input = "style-module--input--8rofZ";
export var largeArticle1 = "style-module--largeArticle1--h6jeQ";
export var mainWrapper = "style-module--mainWrapper--g8DBz";
export var subTitle = "style-module--subTitle--Gy82T";
export var submit = "style-module--submit--TDXx0";
export var subscribe = "style-module--subscribe--eOCie";
export var title = "style-module--title--15vyU";
export var wrapper = "style-module--wrapper--QStV2";