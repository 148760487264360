import React,{useState} from "react";
import Card from "../../../atoms/Magazine/cardCategory/index";
import * as style from "./style.module.scss";
import PopupSlider from "../../../atoms/popupSlider";
import { useStaticQuery, graphql } from "gatsby";

const Index = () => {

  const { allWpPost } = useStaticQuery(
    graphql`
      query {
        allWpPost(limit: 6) {
          edges {
            node {
              slug
              title
              databaseId
              popup {
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                popupContent
              }
              featuredImage {
                node {
                  sourceUrl
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
    );

  const [popupShow, setPopupShow] = useState(false);
  const [index, setindex] = useState(0);

  const handelClick = (index) =>{
    setindex(index);
    setPopupShow(true);
  }

  const closePopUp = () =>{
    setPopupShow(false);
  }

  const mobileCard = () => {
    return (
      allWpPost?.edges &&
      allWpPost.edges.map((res, index) => {
        return (
          <Card
            handelClick = {handelClick}
            index = {index}
            img={res.node.featuredImage.node.localFile}
            popupImage={res?.node.popup?.image?.localFile}
            content={res?.node.popup.popupContent}
            key={res.name}
            text={res.content}
            to={`/blog/${res.node.slug}`}
            category={res.node.title}
          />
        );
      })
    );
  };

  return (
    <div>
      <h1 className={style.title}>Series - Our concepts in brief</h1>
      <div className={style.containerPosts}>
        {mobileCard()}
      </div>
      {popupShow && <PopupSlider index={index} list={allWpPost?.edges}  closePopUp = {closePopUp} />}
    </div>
  );
};

export default Index;
