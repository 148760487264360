// extracted by mini-css-extract-plugin
export var arrowsArticles = "style-module--arrowsArticles--PDKbr";
export var arrowsWrapper = "style-module--arrowsWrapper--KuPE3";
export var arrowsliderwrapper = "style-module--arrowsliderwrapper--HIawL";
export var mainWrapper = "style-module--mainWrapper--OVVbR";
export var next2 = "style-module--next2--c+V9z";
export var prev2 = "style-module--prev2--E8YfH";
export var swiperButtonNext = "style-module--swiper-button-next--Kmcut";
export var swiperButtonPrev = "style-module--swiper-button-prev--XL4MU";
export var title = "style-module--title--a4G6b";
export var wrapper = "style-module--wrapper--Xypdo";