import React from "react";
import * as styles from "./styles.module.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { AiOutlineClose } from "react-icons/ai";


function ArticlePopup({ title, category, content, image, closePopUp }) {


  const imagePopup = getImage(image);

  return (
    <div className={styles.articlePopup}>
      <div className={styles.popupBox}>
        <AiOutlineClose onClick={closePopUp} />
        <GatsbyImage image={imagePopup} />
        <div className={styles.main}>
        <div className={styles.contentWrapper}>
          <h1 className={styles.title}>{category}</h1>
          <div
            dangerouslySetInnerHTML={{ __html: content }}
            className={styles.content}
          ></div>
        </div>
      </div>
      </div>
    </div>
  );
}
export default ArticlePopup;
