import React from 'react';
import * as style from './style.module.scss'
import CaseStudyItem from '../../../atoms/caseStudyItem';
import bgimage1 from "../../../../images/46.jpg";
import bgimage2 from "../../../../images/pc2.1.jpg";
import bgimage3 from "../../../../images/47.jpg";
export default function caseStudies() {
  return <div className={style.caseStudies}>
    <div className={style.wrapper}>
    <h1 className={style.title}>Case Studies</h1>
    <div className={style.mainWrapper}>
    <CaseStudyItem 
    description="4 new prespectives"
    image={bgimage3}
    extraClass="largeArticle"
    link="/blog/4-new-ways-to-look-at-your-presentations"
    />
    <CaseStudyItem 
    description="6 hacks of improvement"
    image={bgimage1}
    extraClass="largeArticle"
    link="/blog/6-steps-to-improve-your-presentation"
    />
    <CaseStudyItem 
    description="10 steps to excellence"
    image={bgimage2}
    extraClass="largeArticle1"
    link="/blog/10-steps-to-awesome-presentations"

    />
    <div>
    </div>
    </div>
    </div>
    {/* <div className={style.subscribe}>
      <h2 className={style.subTitle}>Don't want to miss anything?</h2>
      <p className={style.desc}>Get weekly updates on the newest design stories, case studies and tips right in your mailbox.</p>
      <div className={style.input}>
      <input type="email" className={style.email} id="email" name="email" placeholder='Your email'/>
      <input type="submit" value="Subscribe" className={style.submit} />
      </div>
    </div> */}
  </div>;
}
