import React from 'react';
import * as style from './style.module.scss'
import CaseStudyItem from '../../../atoms/caseStudyItem';
import ArticlesSliderItem from '../../../atoms/Magazine/articlesSliderItem';
import image1 from '../../../../images/girlblue.jpg'
import image2 from '../../../../images/girleye.jpg'
import bgimage4 from '../../../../images/ArticleProductivity.jpg'
import bgimage5 from '../../../../images/Article10xproductivity.jpg'
import bgimage6 from '../../../../images/ArticleDigitizingsales.jpg'
import HalfArticle from '../halfArticle';

export default function Popular() {
    const data = [
        {
            description:"University Collaborations – Work opportunities and knowledge exchange",
            date:"January 10, 2022",
            bgImage1: image1,
            link: "/blog/joining-up-with-universities-and-education-institutions",
            description2:"Diversity a Superpower – How social inclusions and global mindset matter  ",
            date2:"February 1, 2022",
            bgImage2: image2,
            link2: "/blog/diversity-a-super-power",
            index:0,

        }
    ];
  return <div>
      <div className={style.popular}>
          <div className={style.wrapper}>
          <h1>Popular</h1>
          <div className={style.articles}>
      {data.map(item => (
                     <ArticlesSliderItem index={item.index} description={item.description} description2={item.description2} date={item.date} date2={item.date2}  bgImage1={item.bgImage1} bgImage2={item.bgImage2} link={item.link} link2={item.link2} extraClass="noSpace"/>
                ))}
                </div>
                <div className={style.caseStudies}>
                 <CaseStudyItem 
                    description="Productivity 2022 - Inspirations"
                    image={bgimage4}
                    extraClass="largeArticle"
                    link="/blog/productivity-2022"
                    />
                <CaseStudyItem 
                    description="10x Productivity – it’s all about applying                    "
                    image={bgimage5}
                    extraClass="largeArticle"
                    link="/blog/10x-productivity"
                />
                <CaseStudyItem 
                    description="Digitizing sales – more efficient sales cycle"
                    image={bgimage6}
                    extraClass="largeArticle"
                    link="/blog/digitizing-sales-shorter-cycles"
                />
    </div>
    <div className={style.halfArticle}>
                <HalfArticle/>
    </div>
      </div>
      </div>
  </div>;
}
