import React from "react";
import * as styles from "./style.module.scss";
import Button from "../../../atoms/Magazine/Button";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function index() {
  return (
    <div className={styles.onbardingContent}>
      <div className={styles.backgorund}>
      </div>
      <div className={styles.container}>
        <div className={styles.boxHolder}>
        <div className={styles.box}>
        <h2>The future is here 
with us.<span> Take a look!</span></h2>
        {/* <div className={styles.play}>
          <FontAwesomeIcon size={30} icon={faPlay} />
        </div> */}
        <Button colorText="#EC7600" to="/blog/the-future-of-work" colorIcon="#fff" />
        </div>
        </div>
      </div>
    </div>
  );
}
