import React from 'react';
import * as styles from './style.module.scss';
import {
    faArrowRight
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Link } from 'gatsby';

export default function index({to='#', colorText='#fff', colorIcon="#EC7600" , iconColor="#EC7600"}) {
    return (
        <Link to={to}>
            <div className={styles.readMoreBtn}>
            <p style={{color:colorText}}>Read More</p>
            <div style={{borderColor:colorIcon}} className={styles.icon} >
                <FontAwesomeIcon style={{color: iconColor}}  size={30} icon={faArrowRight}/>
            </div>
            </div>
            
        </Link>
    )
}
