import React, { useState } from "react";
import * as style from "./style.module.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ArticlePopup from "../../Popup";
import Slider from "./Swiper";

export default function Index({
  img = "carousel2.webp",
  text = "Innovation at the core of every business in 2021",
  to = "#",
  category = "Catergory",
  popupImage = "carousel2.webp",
  content = "popup content",
  link,
  handelClick,
  index
}) {
  const [isPopup, setPopup] = useState(false);
  const image = getImage(img);
  function closePopup() {
    setPopup(false);
  }

  const onClick = () =>{
    handelClick(index);
  }

  return (
    <>
      <div onClick={() =>{onClick()}}>
        <div className={style.cardContent}>
          <div className={style.backgorund}>
            <GatsbyImage className={style.saturate} image={image} />
          </div>
          <div className={style.category}>
            <p>{category}</p>
          </div>
          {/* <div className={style.bottom}>
            <p>{text}</p>
            <Link to={to}>Read More</Link>
          </div> */}
        </div>
      </div>
      {isPopup === true ? (
        <>
          <ArticlePopup
            close={closePopup}
            title={category}
            image={popupImage}
            content={content}
          />
        </>
      ) : null}
    </>
  );
}
