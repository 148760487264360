import React from 'react';
import * as style from "./style.module.scss";
import image from '../../../../images/logoHeadeer.png'

export default function logoHeader() {
  return <>
    <div className={style.logoHeader}>
        <div className={style.wrapper}>
            <div className={style.firstHolder}>

                <h2 className={style.title}>Super</h2>
            </div>
            <div className={style.mainHolder}>
            <img src={image} className={style.logo}/>
            </div>
            <div className={style.lastHolder}>

            <h2 className={style.title}>Magazine</h2>
            </div>
        </div>
        </div>
  </>;
}
