import React from 'react';

export default function index() {
  return <>

  </>;
}
































// import React from "react";
// import * as style from "./style.module.scss";
// import Button from "../../../atoms/Magazine/Button";
// import img from "../../../../images/quotemagazine2.webp";

// export default function index({}) {
//   return (
//     <div className={style.storyContainer}>
//       <div className={style.content}>
//         <img src={img} />
//         <div className={style.imagePart}></div>
//         <h3>A little story of our lovely team</h3>
//         <p>
//           Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
//           tempor incididunt ut labore et dolore nunc id. Orci porta non pulvinar
//           neque laoreet suspendisse. Sed egestas magna aliqua.
//           <br/>
//           <br/>
//            Enim tortor at
//           egestas fringilla phasellus faucibus scelerisque eleifend donec pret
//           incididunt ut labore et dolore nunc id. Orci porta non pulvinar neque
//           laoreet suspendisse. Sed egestas magna aliqua.
//         </p>
//         <Button colorText="#fff" colorIcon="#fff" />
//       </div>
//     </div>
//   );
// }
