import React from 'react';
import * as style from './style.module.scss';
import Button from "../../../atoms/Magazine/Button";


export default function halfArticle() {
  return <div>
      <div className={style.halfArticle} id="subscribe">
            <div className={style.imgHolder}>

            </div>
            <div className={style.contentHolder}>
                <h2 className={style.desc}>Follow what comes next in the WiZR Magazine,<br/> practical and visionary articles to apply and delight.</h2>
                 {/* <Button colorText="rgba(255, 255, 255, 0.604)" colorIcon="rgba(255, 255, 255, 0.604)" iconColor='rgba(255, 255, 255, 0.604)' /> */}
            </div>
      </div>
  </div>;
}
