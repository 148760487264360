import React from "react";
import HeroBanner from "../../../atoms/HeroBanner";
import Button from "../../../atoms/Magazine/Button/index"
import * as styles from "./bannerModules.module.scss";
import {Link} from 'gatsby';
import image from '../../../../images/mw-article.png'
import {
  faChevronRight
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
function index() {
  return (
    <div className={styles.bannerMagazine}>
      <div className={styles.content}>
        <h1 data-aos='fade-right' data-aos-delay='100'>Welcome to the
          WiZR Universe,<br/>
          better than real...</h1>
        {/* <p data-aos='fade-right' data-aos-delay='100'>Welcome to the WiZR World , better than real ...</p> */}
        
        <Button to="/blog/storytelling-and-success" colorText="#EC7600" colorIcon="#fff" />
        
      </div>
        <img src={image} className={styles.logo}/>
    </div>
  
  );
}

export default index;
