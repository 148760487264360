import React from "react";
import { useEffect } from "react";
import Footer from "../components/atoms/footer";
import Header from "../components/atoms/header";
import Banner from "../components/molecules/Magazine/banner/index";
import CategoryPosts from "../components/molecules/Magazine/posts/index";
import ArticlesSlider from "../components/molecules/Magazine/articlesSlider/index";
import Story from "../components/molecules/Magazine/story/index";
import Onboarding from "../components/molecules/Magazine/onborading/index";
import WizrWay from "../components/molecules/Magazine/wizrWay";
import Head from "../components/ogranisms/head";
import CaseStudies from "../components/molecules/Magazine/caseStudies"
import LogoHeader from '../components/molecules/Magazine/logoHeader/index'
import Popular from "../components/molecules/Magazine/popular"
import FreeTrial from "../components/atoms/freeTrial";
import InstaSlider from "../components/molecules/Magazine/instaSlider";
import SecondSubscribe from "../components/molecules/Magazine/SecondSubscribe"
import AOS from "aos";
import "aos/dist/aos.css";
function Modules() {
  useEffect(() => {
    AOS.init({
      disable: "mobile",
      duration: 1500,
    });
  }, []);
  return (
    <>
      <Head
        title="Super WiZR Magazine"
        description="Welcome to the WiZR World , better than real"
        ogTitle="Magazine"
      />
      <main>
        <Header />
        <LogoHeader/>
        <Banner />
        <CategoryPosts />
        <ArticlesSlider />
        <CaseStudies />
        <Story />
        <Onboarding />
        <WizrWay />
        <Popular />
        <SecondSubscribe />
        <InstaSlider />
        <FreeTrial />
        <Footer />
      </main>
    </>
  );
}

export default Modules;
